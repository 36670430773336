import NextImage from 'next/image';
import React from 'react';
import styled from 'styled-components';

import { A11y, Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';

const TESTIMONIALS = [
    {
      content: `Várias pessoas me pediram se eu não faria chá de bebê para a Joana. Quem me conhece pessoalmente, sabe que sou mais tímida para essas coisas 😅
  
  Quando estava grávida da Aurora, era na época da pandemia, então não podia fazer nada. E aí acabou que a Aurora ganhou um “chárreata”. Fizeram surpresa e passaram lá em casa deixar os presentinhos pra Aurora 🥰
  
  Pensando nisso, para não deixar passar em branco da Joana, resolvi fazer um “chá de bebê on-line”
  
  Sei que tem muita gente por aqui que gostaria de mandar um presentinho para a Joaninha.
  
  Fiz através da plataforma @meunascimento da @drajanuzzi`,
      author: '@leticiawutzke',
      image: '/testimonials/leticiawutzke.png',
    },
    {
      content: `Eu fiz de surpresa pro meu irmão e minha cunhada ainda imprimi os recadinhos do site e coloquei numa caixa pra eles terem pra sempre 😍`,
      author: "@nandamppimenta",
      image: '/testimonials/nandamppimenta.png',
    },
    {
      content: `Conseguimos praticamente tudo que faltava pro enxoval na @crescieperdi_santoamaro.
  
  Liz será a princesa dos brechós, achei que valeu super a pena! Como fizemos parte do chá pelo @meunascimento, usamos o crédito que tinha lá e ainda sobrou um pouco.`,
      author: "@indyraasilveira",
      image: '/testimonials/indyraasilveira.png',
    },
    {
      content: `Pra você fazer o seu convites, lista de presentes e tudo mais, na @meunascimento você não paga nada! Inclusive tem uma coisa muito boa que eu precisava muito, que era a confirmação do evento. Meus convidados entravam no link e confirmam se vão no seu evento.`,
      author: "@fernandaqueiroz",
      image: '/testimonials/fernandaqueiroz.png',
    },
    {
      content: `O @meunascimento é uma solução de lista de chá de bebê / aniversário, pra vc criar a lista de presentes e receber os presentes em dinheiro. É maravilhoso(sooo) pra reduzir os presentes desnecessários e usar o $ de forma mais útil (vacinas, rede de apoio, escola, etc).`,
      author: "@drajanuzzi",
      image: '/testimonials/drajanuzzi.png',
    },
  ];
  

export default function Testimonials() {
  return (
    <Wrapper>
      <Title>Depoimentos</Title>
      <Swiper
        modules={[Pagination]}
        slidesPerView={1}
        pagination={{
            clickable: true, 
            dynamicBullets: true,
        }}
        autoplay={{ delay: 8000 }}
        loop
        autoHeight={true}
      >
        {TESTIMONIALS.map((testimonial, idx) => (
          <SwiperSlide key={idx}>
            <Card>
              <Stars>⭐️⭐️⭐️⭐️⭐️</Stars>
              <Content>{testimonial.content}</Content>
              <Author>
                <Avatar>
                  <NextImage src={testimonial.image} alt="avatar" width={24} height={24} />
                </Avatar>
                <span>{testimonial.author}</span>
              </Author>
            </Card>
          </SwiperSlide>
        ))}
      </Swiper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background: #8B8EE8;
  padding: 5rem 2rem;
  text-align: center;

  .swiper-pagination {
    position: relative;
    margin-top: 0;
    bottom: 0;
  }

  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    background:rgba(255, 246, 232, 0.57);
    opacity: 1;
    margin: 0 5px !important;
    border-radius: 50%;
    transition: all 0.3s ease;
  }

  .swiper-pagination-bullet-active {
    background: #FFF;
  }
`;

const Title = styled.h2`
    font-family: 'P22 Mackinac Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 120%;
    color: #ffffff;;
    text-align: center;
    margin-bottom: 2rem;

    @media (max-width: 478px) {
        font-size: 32px !important;
        padding: 20px;
    }
`;

const Card = styled.div`
  background: #FFF7EC;
  border-radius: 10px;
  padding: 2rem;
  width: 80%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  text-align: left;
  max-height: 538px;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    width: 90%;
    max-height: 688px;
  }
`;


const Stars = styled.p`
  color: #ffcc00;
  font-size: 1.6rem;
`;

const Content = styled.p`
  margin-top: 1rem;
  font-size: 1.6rem;
  color: #333;
  white-space: pre-line;
`;

const Author = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2rem;
  font-size: 1.4rem;
  color: #333;

  span {
    margin-left: 0.5rem;
  }
`;

const Avatar = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  overflow: hidden;
`;

